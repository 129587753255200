import { CollectionViewer, DataSource } from '@angular/cdk/collections'
import { PaginatorInfoFragment } from '@app-graphql/schema'
import { distinctUntilChangedEquals } from '@app-lib/rxjs.lib'
import { Unpacked } from '@app-types/common.types'
import { Query, QueryRef } from 'apollo-angular'
import {
    BehaviorSubject,
    merge,
    Observable,
    of,
    startWith,
    Subject,
    Subscription,
    switchMap,
    withLatestFrom,
} from 'rxjs'
import { map, tap } from 'rxjs/operators'

type ExtractQuery<T> = T extends Query<infer Q, any> ? Q : never
type ExtractVariables<T> = T extends Query<any, infer V> ? V : never

type DeepKeyOf<T> = (
    [T] extends [never] ? '' :
        T extends object ? (
            { [K in Exclude<keyof T, symbol>]:
                                `${K}${DotPrefix<DeepKeyOf<T[K]>>}` }[
                Exclude<keyof T, symbol>]
        ) : ''
) extends infer D ? Extract<D, string> : never

type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`

// TODO: Replace this when the pagination types are available in the schema
type PaginatorInput = {
    page: number
    first: number
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Filter<DataType> = {
    field: DeepKeyOf<DataType>
    operator: 'EQ'
    value: any
}

export class ApolloDatasource<
    Service extends Query<any, any>,
    Key extends keyof QueryType,
    QueryType = ExtractQuery<Service>,
    VariablesType = Omit<ExtractVariables<Service>, keyof PaginatorInput>,
    ReturnType = Unpacked<QueryType[Key], true>,
    DataType = ReturnType extends { data: infer T } ? T : never,
> implements DataSource<DataType> {
    protected queryRef: QueryRef<QueryType, VariablesType & PaginatorInput>
    private readonly paginatorInput: PaginatorInput = {
        page: 1,
        first: 8,
    }
    private page$: BehaviorSubject<number>
    private readonly first$: BehaviorSubject<number>
    private sortBy$ = new Subject<{
        path: DeepKeyOf<Unpacked<QueryType[Key]>>
        direction: 'ASC' | 'DESC'
    }>()
    protected paginatorInput$: Observable<PaginatorInput>
    private readonly dataSubscription: Subscription
    public loading$: Observable<boolean>
    public paginatorInfo$: Observable<PaginatorInfoFragment>
    protected key: Key
    private readonly polling: boolean | undefined
    protected loadingComplete$ = new Subject<void>()

    constructor(
        service: Service,
        key: Key,
        parameters: VariablesType,
        paginatorInput: PaginatorInput,
        polling?: boolean,
    ) {
        this.key = key
        this.polling = polling
        this.paginatorInput = paginatorInput ?? this.paginatorInput
        this.page$ = new BehaviorSubject<number>(this.paginatorInput.page)
        this.first$ = new BehaviorSubject<number>(this.paginatorInput.first)
        this.paginatorInput$ = this.page$.pipe(
            withLatestFrom(this.first$),
            distinctUntilChangedEquals(),
            map(([page, first]) => ({
                page,
                first,
            })),
        )
        this.queryRef = service.watch({
            ...parameters,
            ...this.paginatorInput,
        }, {
            pollInterval: this.polling ? 1000 : undefined,
        })

        this.dataSubscription = this.paginatorInput$.pipe(
            switchMap((currentPaginatorInput) => this.queryRef.refetch(
                currentPaginatorInput as VariablesType & PaginatorInput,
            )),
        ).subscribe()

        this.paginatorInfo$ = this.queryRef.valueChanges.pipe(
            map((result) => {
                return ((result.data[this.key] as any).paginatorInfo ?? []) as PaginatorInfoFragment
            }),
        )

        this.loading$ = merge(
            this.paginatorInput$.pipe(map(() => true)),
            this.loadingComplete$.pipe(map(() => false)),
        ).pipe(
            startWith(true),
        )
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public connect(collectionViewer?: CollectionViewer): Observable<DataType[]> {
        return merge(
            this.paginatorInput$.pipe(map(() => [])),
            this.queryRef.valueChanges.pipe(
                map((result) => {
                    this.loadingComplete$.next()
                    if (! result.data) {
                        return [] as DataType[]
                    }
                    return ((result.data[this.key] as any).data ?? [])
                }),
            ),
        )
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public disconnect(collectionViewer: CollectionViewer) {
        if (this.polling) {
            this.queryRef.stopPolling()
        }
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe()
        }
    }

    public nextPage() {
        this.page$.next(this.page$.getValue() + 1)
    }

    public previousPage() {
        this.page$.next(Math.max(this.page$.getValue() - 1, 1))
    }

    public setPageSize(size: number) {
        this.first$.next(Math.max(size, 1))
        this.goToPage(1)
    }

    public goToPage(page: number) {
        this.page$.next(Math.max(page, 1))
    }

    public sortBy(path: DeepKeyOf<Unpacked<QueryType[Key], true>>, direction: 'ASC' | 'DESC') {
        this.sortBy$.next({
            path,
            direction,
        })
        this.goToPage(1)
    }

    public filter() {
        this.goToPage(1)
    }
}

export class DatasourceTransformer<
    Service extends Query<any, any>,
    Key extends keyof QueryType,
    QueryType = ExtractQuery<Service>,
    VariablesType = Omit<ExtractVariables<Service>, keyof PaginatorInput>,
    ReturnType = QueryType[Key],
    DataType = ReturnType extends { data: infer T } ? Unpacked<T, false> : never,
> extends ApolloDatasource<
    Service,
    Key,
    QueryType,
    VariablesType,
    ReturnType,
    DataType
    > {
    constructor(
        service: Service,
        key: Key,
        parameters: VariablesType,
        paginatorInput: PaginatorInput,
        private transformer: (a: DataType[]) => Observable<any[]>,
        polling?: boolean,
    ) {
        super(service, key, parameters, paginatorInput, polling)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public override connect(collectionViewer?: CollectionViewer) {
        return merge(
            this.paginatorInput$.pipe(map(() => [[], true])),
            this.queryRef.valueChanges.pipe(
                map((result) => {
                    if (! result.data) {
                        return [[] as DataType[], false]
                    }

                    return [((result.data[this.key] as any).data ?? []), false]
                }),
            ),
        ).pipe(
            switchMap(([data, loading]) => this.transformer(data).pipe(
                withLatestFrom(of(loading)),
            )),
            tap(([, loading]) => {
                if (! loading) {
                    this.loadingComplete$.next()
                }
            }),
            map(([data]) => data),
        )
    }
}
