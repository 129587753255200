import { Subscription } from 'rxjs'
import { tap } from 'rxjs/operators'
import { isNil } from 'ramda'
import { RendererOptions } from 'instantsearch.js'
import connectAutocomplete, {
    AutocompleteConnectorParams as CParams,
    AutocompleteWidgetDescription as WDescription,
} from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete'

import { Component, OnInit, Inject, forwardRef, Output, EventEmitter, OnDestroy } from '@angular/core'

import { SearchService } from '@app-services'
import { AlgoliaInstantsearchComponent } from '@app-domains/algolia/components/algolia-instantsearch.component'
import { AbstractInstantsearchWidgetComponent } from '@app-domains/algolia/abstract-instantsearch-widget.component'
import { Product } from '@app-domains/content-blocks/components/product-card/product-card.types'

@Component({
    selector: 'app-search-hits-products',
    templateUrl: './search-hits-products.component.html',
    styleUrls: [ '../search/search.component.scss' ],
})
export class SearchHitsProductsComponent extends AbstractInstantsearchWidgetComponent<
WDescription,
CParams
> implements OnInit, OnDestroy {

    public override readonly widgetName = 'SearchHitsProductsComponent'

    @Output()
    public resultClicked = new EventEmitter<void>()

    @Output()
    public loading = new EventEmitter<boolean>()

    public override updateState = (
        state: WDescription['renderState'] & RendererOptions<CParams>,
    ) => {
        this.state = state
        this.loading.emit(false)
    }

    private subscription: Subscription

    constructor(
        @Inject(forwardRef(() => AlgoliaInstantsearchComponent))
        public instantSearchInstance: AlgoliaInstantsearchComponent,
        private searchService: SearchService,
    ) {
        super()
    }

    public override ngOnInit(): void {
        this.createWidget(connectAutocomplete, {})
        super.ngOnInit()

        this.subscription = this.searchService.query$.pipe(
            tap((query) => {
                if (isNil(query)) {
                    return
                }

                if (query.length >= 3) {
                    setTimeout(() => {
                        this.state?.refine(query)
                        this.loading.emit(true)
                    }, 300)
                } else if (this.state) {
                    this.state.indices.forEach((index) => {
                        index.hits = []
                    })
                }
            }),
        ).subscribe()
    }

    public getQueryID(): string | undefined {
        return this.instantSearchInstance.instantSearchInstance.helper?.lastResults?.queryID
    }

    public resultClick(product: Product, index: number): void {
        this.instantSearchInstance.instantSearchInstance.sendEventToInsights({
            insightsMethod: 'clickedObjectIDsAfterSearch',
            payload: {
                index: this.instantSearchInstance.instantSearchInstance.indexName,
                eventName: 'Click item',
                queryID: this.getQueryID() ?? '',
                objectIDs: [product.ean],
                positions: [index + 1],
            },
            eventType: 'click',
            widgetType: 'search',
        })

        this.resultClicked.emit()
        this.searchService.addRecentSearch(product, this.getQueryID() ?? '')
    }

    public override ngOnDestroy(): void {
        super.ngOnDestroy()
        this.subscription.unsubscribe()
    }
}
