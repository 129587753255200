import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client'

import { Injectable } from '@angular/core'
import { FormBuilder, FormControl } from '@angular/forms'

import { ApolloDatasource } from '@app-lib/apollo-datasource.lib'
import {
    OrderNumbersAndStatusQuery,
    OrderNumbersAndStatusQueryService,
    OrderNumbersAndStatusQueryVariables,
    OrderQuery,
    OrderQueryService,
    OrderQueryVariables,
    OrdersByReferenceAndIdQueryService,
    OrdersByReferenceAndIdQueryVariables,
    OrderStateEnum,
    OrderTypeEnum,
    SimpleOrdersQueryService,
    SortEnum,
} from '@app-graphql/schema'
import { TranslationLabel } from '@app-types/common.types'

@Injectable({
    providedIn: 'root',
})
export class OrderService {

    private excludedOrderStatusEnums = ['send', 'not_send', 'processing']

    public filters = this.formBuilder.group({
        orderType: new FormControl<OrderTypeEnum>(OrderTypeEnum.All, { nonNullable: true }),
        orderStatus: new FormControl<OrderStateEnum>(OrderStateEnum.All, { nonNullable: true }),
        sortByOrderDate: new FormControl<SortEnum>(SortEnum.Descending, { nonNullable: true }),
        sortByDeliveryDate: new FormControl<SortEnum>(SortEnum.Descending, { nonNullable: true }),
    })

    public selectedOrders = new FormControl<string[]>([], {
        nonNullable: true,
    })

    public searchOrder: FormControl = new FormControl('')

    public orderStatusEnumLabels: TranslationLabel[] = Object.values(OrderStateEnum).map(
        (value): TranslationLabel => {
            return {
                label: `orders.status-filter.${ value }`,
                value,
            }
        },
    ).filter((x) => ! this.excludedOrderStatusEnums.includes(x.value.toLowerCase()))

    public orderTypeEnumLabels: TranslationLabel[] = Object.values(OrderTypeEnum).map(
        (value): TranslationLabel => {
            return {
                label: `orders.types-filter.${ value }`,
                value,
            }
        },
    )

    public selectAllControl = new FormControl(false, {
        nonNullable: true,
    })

    constructor(
        private orderQueryService: OrderQueryService,
        private simpleOrdersQueryService: SimpleOrdersQueryService,
        private orderNumbersAndStatusQueryService: OrderNumbersAndStatusQueryService,
        private ordersByReferenceAndIdQueryService: OrdersByReferenceAndIdQueryService,
        public formBuilder: FormBuilder,
    ) {
    }

    public getOrder(input: OrderQueryVariables): Observable<ApolloQueryResult<OrderQuery>> {
        return this.orderQueryService.fetch(input)
    }

    public getOrderNumbersAndStatus(input: OrderNumbersAndStatusQueryVariables)
        : Observable<ApolloQueryResult<OrderNumbersAndStatusQuery>> {
        return this.orderNumbersAndStatusQueryService.fetch(input)
    }

    public getDatasource(page: number = 1): ApolloDatasource<SimpleOrdersQueryService, 'orders'> {
        return new ApolloDatasource(this.simpleOrdersQueryService, 'orders', {
            orderState: this.filters.controls.orderStatus.value,
            orderType: this.filters.controls.orderType.value,
            dateOrder: this.filters.controls.sortByOrderDate.value,
            nextDelivery: this.filters.controls.sortByDeliveryDate.value,
        }, {
            page,
            first: 8,
        })
    }

    public getOrdersByReferenceAndOrderId(
        { orderState, orderType, dateOrder, nextDelivery, search, first, page }: OrdersByReferenceAndIdQueryVariables)
        : ApolloDatasource<OrdersByReferenceAndIdQueryService, 'ordersSearch'> {
        return new ApolloDatasource(this.ordersByReferenceAndIdQueryService, 'ordersSearch', {
            orderState,
            orderType,
            dateOrder,
            nextDelivery,
            search,
        }, {
            first,
            page: page ?? 1,
        })
    }

}
