import { animate, group, style, transition, trigger } from '@angular/animations'
import {
    ProductDownloadFragment,
    ProductImageFragment,
    VariantsFragment,
    ProductCategoryFragment,
} from '@app-graphql/schema'
import { Color } from '@app-types/algolia.types'

export namespace ProductCard {

    export interface AnimationParams {
        value: 'expand' | 'none'
    }

    const easing = 'cubic-bezier(0, 0, 0.15, 1)'
    const animationTiming1 = `200ms ${easing}`
    const animationTiming2 = `75ms ${easing}`

    export const hoverAnimation = trigger('hoverState', [
        transition(':enter', [
            group([
                style({
                    transform: 'scale(0.75)',
                }),
                animate(animationTiming1, style({
                    transform: 'scale(1)',
                })),
            ]),
        ]),

        transition(':leave', [
            group([
                style({
                    transform: 'scale(1)',
                    opacity: 1,
                }),
                animate(animationTiming2, style({
                    transform: 'scale(0.75)',
                    opacity: 0,
                })),
            ]),
        ]),
    ])
}

export type Dimensions = {
    height: number
    length: number
    units: string
    width: number
}

type Package = {
    depthInMm: number
    ean: String
    lengthMm: number
    productCode: String
    quantity: number
    volM3: number
    weightInKg: number
    widthMm: number
}

export interface Product {
    ambianceImage: string
    brand: string
    categories?: ProductCategoryFragment[]
    categoryNames?: string[]
    codeLocal: string
    collectionName: string
    colors: Color[]
    country?: string
    currency?: string
    debtorPrice: number
    deliveryTimeInWeeks: number | null | undefined
    description?: string
    dimensions?: Dimensions
    downloads?: ProductDownloadFragment[]
    ean: string
    fullDescription?: string
    fsc: boolean
    group?: string
    id: string
    images?: ProductImageFragment[]
    inFavourites: boolean
    inStock: boolean
    index?: number
    listName?: string
    materials: string[]
    name: string
    newDebtorPrice: number
    newProduct: boolean
    packages: Package[]
    primaryImage: string
    price?: number
    productOrderSteps?: number
    quantity?: number
    range?: string
    slug: string
    status: string
    statusEnum: string
    suggestedRetailPrice: number
    suggestedStandardRetailPrice: number
    suggestedRetailPriceISE: number
    suggestedStandardRetailPriceISE: number
    subColors: Color[]
    subMaterials: string[]
    totalPackages: string
    variants?: VariantsFragment | null
    videos?: string[]
    wideImage: string
}
