import { combineLatest, firstValueFrom, Observable, of, switchMap } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { ApolloQueryResult } from '@apollo/client'

import { Injectable } from '@angular/core'
import { locales, Localization } from '@aa-app-localization'

import { Product } from '@app-domains/content-blocks/components/product-card/product-card.types'
import { AlgoliaHit } from '@app-types/algolia.types'
import { transformHit } from '@app-lib/product.lib'

import {
    HighlightedProductsQueryService,
    ProductBySlugQuery,
    ProductBySlugQueryVariables,
    ProductFragment,
    ProductImageTypeEnum,
    ProductsByEansQuery,
    ProductsByEansQueryService,
    ProductsByEansQueryVariables,
    ProductsOrderPropertiesQuery,
    ProductsOrderPropertiesQueryService,
    ProductsOrderPropertiesQueryVariables,
    SimpleProductFragment,
    SimpleProductsByEanQuery,
    SimpleProductsByEanQueryService,
    SimpleProductsByEanQueryVariables,
    SuggestedRetailPricesEnum,
} from '@app-graphql/schema'

import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { ProductHistoryService } from '@app-domains/product/services/product-history/product-history.service'
import { AuthService, WishlistService } from '@app-services'
import Locale = Localization.Locale

export type ObservableInput<T> =
    | Observable<T>
    | AsyncIterable<T>
    | PromiseLike<T>
    | ArrayLike<T>
    | Iterable<T>

export type ObservableInputTuple<T> = {
    [K in keyof T]: ObservableInput<T[K]>
}

@Injectable({
    providedIn: 'root',
})
export class ProductService {

    currentProduct?: ProductFragment

    constructor(
        private productsByEanQueryService: ProductsByEansQueryService,
        private simpleProductsByEanQueryService: SimpleProductsByEanQueryService,
        private productsOrderPropertiesQueryService: ProductsOrderPropertiesQueryService,
        private highlightedProductsQueryService: HighlightedProductsQueryService,
        private productHistoryService: ProductHistoryService,
        private wishlistService: WishlistService,
        private authService: AuthService,
        private localization: LocalizationService,
    ) {
    }

    public getProductBySlug(input: ProductBySlugQueryVariables, locale: Locale)
        : Observable<ApolloQueryResult<ProductBySlugQuery>> {
        return this.productHistoryService.fetch(input, locale).pipe(
            tap((result) => this.currentProduct = result.data?.productBySlug ?? undefined),
            tap((result) => {
                if (result.data.productBySlug) {
                    const {
                        ean,
                        translations,
                    } = result.data.productBySlug
                    for (const l of locales) {
                        this.localization.patchTranslations(l, {
                            ROUTES: {
                                [`product-${ ean }`]: translations[l].slug,
                            },
                        })
                    }
                }
            }),
        )
    }

    public getSimpleProducts(input: SimpleProductsByEanQueryVariables)
        : Observable<ApolloQueryResult<SimpleProductsByEanQuery>> {
        return this.simpleProductsByEanQueryService.fetch(input)
    }

    public getProducts(input: ProductsByEansQueryVariables)
        : Observable<ApolloQueryResult<ProductsByEansQuery>> {
        return this.productsByEanQueryService.fetch(input)
    }

    public getProductsOrderProperties(input: ProductsOrderPropertiesQueryVariables)
        : Observable<ApolloQueryResult<ProductsOrderPropertiesQuery>> {
        return this.productsOrderPropertiesQueryService.fetch(input)
    }

    public getHighlightedProducts(): Observable<SimpleProductFragment[]> {
        return this.highlightedProductsQueryService.fetch().pipe(
            map((result) => result.data.recommendedProducts?.data ?? []),
        )
    }

    public transformSimpleToProduct(simple: SimpleProductFragment, locale: Locale): Product
    public transformSimpleToProduct(simple: SimpleProductFragment[], locale: Locale): Product[]
    public transformSimpleToProduct(
        simple: SimpleProductFragment | SimpleProductFragment[],
        locale: Locale,
    ): Product | Product[] {

        if (Array.isArray(simple)) {
            return simple.map((s) => this.transformSimpleProduct(s, locale))
        }

        return this.transformSimpleProduct(simple, locale)
    }

    public transformHitToProduct(hits: AlgoliaHit): Product
    public transformHitToProduct(hits: AlgoliaHit[]): Product[]
    public transformHitToProduct(
        hits: AlgoliaHit | AlgoliaHit[],
    ): Product | Product[] {
        if (Array.isArray(hits)) {
            return hits.map(transformHit)
        }
        return transformHit(hits)
    }

    public transformProductFragmentToProduct(pf: ProductFragment, locale: Locale): Product
    public transformProductFragmentToProduct(pf: ProductFragment[], locale: Locale): Product[]
    public transformProductFragmentToProduct(
        pf: ProductFragment | ProductFragment[],
        locale: Locale,
    ): Product | Product[] {
        if (Array.isArray(pf)) {
            return pf.map((p) => ProductService.transformProduct(p, locale))
        }
        return ProductService.transformProduct(pf, locale)
    }

    public enhanceProducts<
        T extends readonly unknown[],
        P extends Product | ProductFragment,
    >(
        products: P[],
        deps: [...ObservableInputTuple<T>],
        zipFn: (products: P[], deps: T) => P[],
        enhanceFn: (product: P, deps: T) => P,
    ): Observable<P[]> {
        return combineLatest(deps).pipe(
            map((resolvedDeps) => zipFn(products, resolvedDeps as T).map(
                (product) => enhanceFn(product, resolvedDeps as T)),
            ),
        )
    }

    public enhance<P extends Product | ProductFragment>(products: P[]): Observable<P[]> {
        const productsOrderProperties$ = this.authService.user$.pipe(
            switchMap((user) => user && user.debtorCode
                ? this.getProductsOrderProperties({
                    eans: products.map(p => p.ean),
                })
                : of({
                    data: undefined,
                }),
            ),
            map((result) => result.data?.productsByEan ?? []),
        )
        return this.enhanceProducts(
            products,
            [
                this.wishlistService.wishlist$,
                productsOrderProperties$,
            ],
            (prods, [, productsProperties]) => {
                return prods.map((p) => {
                    const productProperties = productsProperties.find((product) => product.ean === p.ean)!
                    return {
                        ...p,
                        newDebtorPrice: productProperties?.price?.price,
                        debtorPrice: productProperties?.price?.standardPrice,
                        inStock: productProperties?.stock?.quantity > 0,
                    }
                })
            },
            (p, resolved) => {
                const [wishlist] = resolved
                return {
                    ...p,
                    inFavourites: !! wishlist.find((w) => w.ean === p.ean),
                }
            },
        )
    }

    private transformSimpleProduct(item: SimpleProductFragment, locale: Locale): Product {
        const suggestedRetailPricesForDebtor = item.suggestedRetailPrices
            .find((price) => price.type === SuggestedRetailPricesEnum.Debtor)

        return {
            id: item.ean,
            collectionName: item.range,
            name: item.translations[locale].name,
            primaryImage: item.images.find((i) => i.type === ProductImageTypeEnum.Front)?.url ?? '',
            ambianceImage: item.images.find((i) => i.type === ProductImageTypeEnum.Group)?.url ?? '',
            wideImage: '',
            status: item.statusTranslations[locale],
            statusEnum: item.status,
            newProduct: false,
            brand: item.brand?.name ?? '',
            materials: [],
            subMaterials: ! item.subMaterials ? [] : item.subMaterials.map((material) => material[locale]),
            colors: [],
            subColors: ! item.subColors ? [] : item.subColors.map((color) => {
                return {
                    presentation: color.presentation,
                    name: color.translations[locale],
                }
            }),
            dimensions: {
                units: 'cm',
                height: 0,
                width: 0,
                length: 0,
                ...Object.fromEntries(item.dimensions.map((d) => [
                    d.label,
                    d.dimension,
                ])),
            },
            codeLocal: item.codeLocal,
            ean: item.ean,
            slug: item.translations[locale].slug,
            debtorPrice: 0,
            newDebtorPrice: 1,
            inStock: !! item.stock,
            deliveryTimeInWeeks: item.orderProperties ? item.orderProperties?.deliveryInfo.deliverableInWeeks : null,
            suggestedRetailPrice: suggestedRetailPricesForDebtor?.price
                ?? item.suggestedRetailPrices.find((price) => price.type === SuggestedRetailPricesEnum.Nl)!.price,
            suggestedStandardRetailPrice: suggestedRetailPricesForDebtor?.standardPrice
                ?? item.suggestedRetailPrices
                    .find((price) => price.type === SuggestedRetailPricesEnum.Nl)!.standardPrice,
            suggestedRetailPriceISE: item.suggestedRetailPrices.find(
                (price) => price.type === SuggestedRetailPricesEnum.Ise)?.price ?? 0,
            suggestedStandardRetailPriceISE: item.suggestedRetailPrices.find(
                (price) => price.type === SuggestedRetailPricesEnum.Ise)?.standardPrice ?? 0,
            inFavourites: false,
            images: item.images,
            totalPackages: '0',
            packages: [],
            productOrderSteps: 0,
            fsc: item.fsc,
        }
    }

    public static transformProduct(item: ProductFragment, locale: Locale): Product {
        const suggestedRetailPricesForDebtor = item.suggestedRetailPrices
            .find((price) => price.type === SuggestedRetailPricesEnum.Debtor)

        // TODO: Align Product and SimpleProduct
        return {
            id: item.ean,
            collectionName: item.range,
            name: item.translations[locale].name,
            primaryImage: item.images[0]?.url ?? '',
            ambianceImage: item.images[1]?.url ?? '',
            wideImage: item.images[2]?.url ?? '',
            status: item.statusTranslations[locale],
            newProduct: false,
            brand: item.brand?.name ?? '',
            materials: item.materials.map((material) => material[locale]),
            subMaterials: ! item.subMaterials ? [] : item.subMaterials.map((material) => material[locale]),
            colors: item.colors.map((color) => {
                return {
                    presentation: color.presentation,
                    name: color.translations[locale],
                }
            }),
            subColors: ! item.subColors ? [] : item.subColors.map((color) => {
                return {
                    presentation: color.presentation,
                    name: color.translations[locale],
                }
            }),
            downloads: item.downloads,
            dimensions: {
                units: 'cm',
                height: 0,
                width: 0,
                length: 0,
                ...Object.fromEntries(item.dimensions.map((d) => [
                    d.label,
                    d.dimension,
                ])),
            },
            categories: item.categories,
            codeLocal: item.codeLocal,
            ean: item.ean,
            slug: item.translations[locale].slug,
            debtorPrice: 0,
            newDebtorPrice: 1,
            suggestedRetailPrice: suggestedRetailPricesForDebtor?.price
                ?? item.suggestedRetailPrices.find((price) => price.type === SuggestedRetailPricesEnum.Nl)!.price,
            suggestedStandardRetailPrice: suggestedRetailPricesForDebtor?.standardPrice
                ?? item.suggestedRetailPrices
                    .find((price) => price.type === SuggestedRetailPricesEnum.Nl)!.standardPrice,
            suggestedRetailPriceISE: item.suggestedRetailPrices.find(
                (price) => price.type === SuggestedRetailPricesEnum.Ise)?.price ?? 0,
            suggestedStandardRetailPriceISE: item.suggestedRetailPrices.find(
                (price) => price.type === SuggestedRetailPricesEnum.Ise)?.standardPrice ?? 0,
            range: item.range,
            group: item.group,
            description: item.translations[locale].shortDescription,
            fullDescription: item.translations[locale].fullDescription,
            variants: item.variants,
            inStock: false,
            deliveryTimeInWeeks: item.orderProperties ? item.orderProperties?.deliveryInfo.deliverableInWeeks : null,
            images: item.images,
            videos: item.videos,
            country: item.country,
            inFavourites: true,
            totalPackages: item.totalPackages.toString(),
            packages: item.packages,
            productOrderSteps: item.orderProperties?.productOrderSteps,
            fsc: item.fsc,
            statusEnum: item.status,
        }
    }

    public async inWishlist(products: Product[]): Promise<void> {
        const wishlist = await firstValueFrom(this.wishlistService.wishlist$)
        products.map((p) => p.inFavourites = !! wishlist.find((w) => w.ean === p.ean))
    }

}
