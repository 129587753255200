import { TranslationKey } from '@app-pipes/typed-translate/typed-translate.pipe'

export namespace Download {
    export const orderFields = [
        'codeLocal',
        'orderNumber',
        'quantity',
        'reference',
        'deliveryDate',
        'ean',
        'barcode',
        'name',
        'image',
        'priceExclVat',
        'orderUnit',
        'brand',
        'material',
        'color',
    ] as const
    export type OrderFields = typeof orderFields[number]

    export const pricelistFields = [
        'image',
        'articleCode',
        'ean',
        'barcode',
        'description',
        'assortment',
        'productgroup',
        'brand',
        'color',
        'material',
        'dimensions',
        'status',
        'saleUnit',
        'salePrice',
        'consumerPrice',
        'totalPacked',
        'FSC',
        'country',
        'intrastatCode',
        'assemblyRequired',
        'longDescription',
        'saleQuantity',
        'purchasingUnit',
    ] as const
    export type PricelistFields = typeof pricelistFields[number]

    export const productsheetFields = [
        'ean',
        'articleCode',
        'longDescription',
        'color',
        'material',
        'dimensions',
        'saleUnit',
        'salePrice',
        'totalPackages',
        'consumerPrice',
        'country',
    ] as const
    export type ProductsheetFields = typeof productsheetFields[number]

    export const stockfeedFields = [
        'articleCode',
        'ean',
        'freeStock',
        'atp',
        'status',
        'dffShipmentMethod',
    ] as const
    export type StockfeedFields = typeof stockfeedFields[number]

    export const productfeedFields = [
        'ean',
        'articleCode',
        'productName',
        'description',
        'assortiment',
        'productgroup',
        'range',
        'brand',
        'material',
        'color',
        'dimensions',
        'weight',
        'saleUnit',
        'fsc',
        'salePrice',
        'status',
        'consumerPrice',
        'isNew',
        'totalPackages',
        'totalPacked',
        'assamblyRequired',
        'country',
        'intrastatCode',
        'images',
        'image',
        'category',
        'freeStock',
        'minimumOrderQuantity',
        'newArrival',
        'numberOfPackages',
    ] as const
    export type ProductfeedFields = typeof productfeedFields[number]

    export const datasheetFields = [
        'structure',
    ] as const
    export type DataSheetFields = typeof datasheetFields[number]

    export interface FieldsObject {
        orders?: OrderFields[]
        pricelist?: PricelistFields[]
        productsheet?: ProductsheetFields[]
        stockfeed?: StockfeedFields[]
        productfeed?: ProductfeedFields[]
    }

    export interface ProductfeedOptions {
        type: 'xml' | 'json' | 'csv'
        fields: {
            [k in ProductfeedFields]?: string
        }
    }

    export interface PricelistOptions {
        price: 'ISE' | 'regular'
    }

    export interface DatasheetOptions {
        layout: 'linear' | 'nested'
    }

    export interface OptionsObject {
        productfeed?: ProductfeedOptions
        datasheet?: DatasheetOptions
        pricelist?: PricelistOptions
    }

    export type FormConfig<
        C =
        | PricelistFields
        | OrderFields
        | ProductfeedFields
        | ProductsheetFields
        | StockfeedFields,
    > = {
        label: TranslationKey
        control: C
    }[]

    export const productsheetConfig: FormConfig<ProductsheetFields> = [
        {
            label: 'products.article-code',
            control: 'articleCode',
        },
        {
            label: 'products.ean',
            control: 'ean',
        },
        {
            label: 'assortment.dimensions',
            control: 'dimensions',
        },
        {
            label: 'products.material',
            control: 'material',
        },
        {
            label: 'products.color',
            control: 'color',
        },
        {
            label: 'assortment.retail-price',
            control: 'consumerPrice',
        },
        {
            label: 'assortment.product-price',
            control: 'salePrice',
        },
        {
            label: 'assortment.packages',
            control: 'totalPackages',
        },
        {
            label: 'products.country-of-origin',
            control: 'country',
        },
        {
            label: 'assortment.extra-description',
            control: 'longDescription',
        },
        {
            label: 'assortment.pricing-unit',
            control: 'saleUnit',
        },
    ]

    export const pricelistConfig: FormConfig<PricelistFields> = [
        {
            label: 'assortment.photo',
            control: 'image',
        },
        {
            label: 'register.description',
            control: 'description',
        },
        {
            label: 'products.ean',
            control: 'ean',
        },
        {
            label: 'products.article-code',
            control: 'articleCode',
        },
        {
            label: 'orders.barcode',
            control: 'barcode',
        },
        {
            label: 'assortment.pricing-unit',
            control: 'saleUnit',
        },
        {
            label: 'products.purchase-price-vat',
            control: 'salePrice',
        },
        {
            label: 'products.suggested-retail-price-vat',
            control: 'consumerPrice',
        },
        {
            label: 'assortment.assortment',
            control: 'assortment',
        },
        {
            label: 'assortment.productgroup',
            control: 'productgroup',
        },
        {
            label: 'orders.brand',
            control: 'brand',
        },
        {
            label: 'products.color-finish',
            control: 'color',
        },
        {
            label: 'products.material',
            control: 'material',
        },
        {
            label: 'assortment.dimensions',
            control: 'dimensions',
        },
        {
            label: 'orders.status',
            control: 'status',
        },
        {
            label: 'assortment.fsc',
            control: 'FSC',
        },
        {
            label: 'products.country-of-origin',
            control: 'country',
        },
        {
            label: 'assortment.intrastat',
            control: 'intrastatCode',
        },
        {
            label: 'assortment.assembly-required',
            control: 'assemblyRequired',
        },
        {
            label: 'assortment.extra-description',
            control: 'longDescription',
        },
        {
            label: 'assortment.packages',
            control: 'totalPacked',
        },
        {
            label: 'products.purchasing-unit',
            control: 'purchasingUnit',
        },
    ]

    export const datasheetConfig: FormConfig<DataSheetFields> = [
        {
            label: 'orders.structure-layout.linear',
            control: 'structure',
        },
        {
            label: 'orders.structure-layout.nested',
            control: 'structure',
        },
    ]

}
