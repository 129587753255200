import { not } from 'ramda'
import { firstValueFrom, Observable, Subscription } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { TranslateRouterService } from '@endeavour/ngx-translate-router'

import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivationStart, Router } from '@angular/router'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { CategoryDropdown } from '@app-domains/navigation/category-dropdown/category-dropdown'
import { MenuDropdown } from '@app-domains/navigation/menu-dropdown/menu-dropdown'
import { BreakpointsService } from '@app-domains/ui/services/breakpoints/breakpoints.service'
import { LocalesEnum, PositionedPageEnum, ProductImageTypeEnum } from '@app-graphql/schema'
import { randomizeHighlightedProducts } from '@app-lib/brand.lib'
import {
    AuthService,
    BrandsService,
    CategoriesService,
    CheckoutService,
    DebtorService,
    ImpersonationService,
    PageStructureDisplayService,
    ScrollBlockerService,
    ThemesService,
    WishlistService,
} from '@app-services'

interface CategoryLink {
    label: string
    link: string | any[]
}

@Component({
    selector: 'app-site-header',
    templateUrl: './site-header.component.html',
    styleUrls: ['./site-header.component.scss'],
})

export class SiteHeaderComponent implements OnInit, OnDestroy {

    public categories$: Observable<CategoryLink[]>
    public dropdownCategories$: Observable<CategoryDropdown[]>
    public themesDropdown$: Observable<CategoryDropdown>
    public brandsDropdown$: Observable<CategoryDropdown | undefined>

    public navigationIsCollapsed: boolean = false
    public originalNavigationIsCollapsed?: boolean
    public mobileMenuIsOpen: boolean = false
    public searchIsOpen: boolean = false
    public search = this.formBuilder.control('')

    public readonly LocalesEnum = LocalesEnum
    public readonly PositionedPageEnum = PositionedPageEnum

    public aboutUsDropdown: MenuDropdown = {
        dropdownButtonLabel: 'navigation.about-us',
        dropdownItems: [
            {
                itemLabel: 'navigation.dealers',
                routerLink: '/dealers',
                underLine: false,
            },
            {
                itemLabel: 'navigation.brochures',
                ppName: PositionedPageEnum.Brochures,
                underLine: true,
            },
            {
                itemLabel: 'navigation.about-us',
                ppName: PositionedPageEnum.AboutUs,
                underLine: false,
            },
            {
                itemLabel: 'navigation.news',
                routerLink: '/news',
                underLine: false,
            },
            {
                itemLabel: 'navigation.events',
                routerLink: '/events',
                underLine: false,
            },
            {
                itemLabel: 'navigation.jobs',
                externalLink: 'https://werkenbij.deeekhoorn.com/',
                underLine: true,
            },
            {
                itemLabel: 'navigation.service',
                ppName: PositionedPageEnum.Service,
                underLine: false,
            },
            {
                itemLabel: 'navigation.contact',
                ppName: PositionedPageEnum.Contact,
                underLine: false,
            },
        ],
    }

    public aboutUsSubcategory: CategoryDropdown = {
        mainCategoryLabel: 'navigation.about-us',
        mainPageUrl: PositionedPageEnum.AboutUs,
        categories: [
            {
                label: 'navigation.dealers',
                link: '/dealers',
            },
            {
                label: 'navigation.brochures',
                link: PositionedPageEnum.Brochures,
                ppName: PositionedPageEnum.Brochures,
            },
            {
                label: 'navigation.about-us',
                link: PositionedPageEnum.AboutUs,
                ppName: PositionedPageEnum.AboutUs,
            },
            {
                label: 'navigation.news',
                link: '/news',
            },
            {
                label: 'navigation.events',
                link: '/events',
            },
            {
                label: 'navigation.jobs',
                externalLink: 'https://werkenbij.deeekhoorn.com/',
            },
            {
                label: 'navigation.service',
                link: PositionedPageEnum.Service,
                ppName: PositionedPageEnum.Service,
            },
            {
                label: 'navigation.contact',
                link: PositionedPageEnum.Contact,
                ppName: PositionedPageEnum.Contact,
            },
        ],
        highlights: [],
    }

    private userSub: Subscription
    public userDropdown: MenuDropdown = {
        dropdownButtonText: '',
        noUpperCase: true,
        icon: 'user',
        dropdownItems: [
            {
                itemLabel: 'navigation.my-dashboard',
                routerLink: '/dashboard',
                underLine: true,
            },
            {
                itemLabel: 'navigation.my-orders',
                routerLink: '/orders',
                underLine: false,
            },
            {
                itemLabel: 'navigation.my-assortment',
                routerLink: '/assortment',
                underLine: false,
            },
            {
                itemLabel: 'navigation.productdata',
                routerLink: '/data-feeds',
                underLine: true,
            },
            {
                itemLabel: 'navigation.my-data',
                routerLink: '/my-data',
                underLine: false,
            },
            {
                itemLabel: 'navigation.sign-out',
                clickEvent: () => {
                    this.impersonationService.stopImpersonation()
                    this.authService.logout()
                },
                underLine: false,
            },
        ],
    }

    private subscriptions: Subscription[] = []

    constructor(
        private readonly categoriesService: CategoriesService,
        public router: Router,
        private readonly formBuilder: FormBuilder,
        public readonly authService: AuthService,
        public readonly localization: LocalizationService,
        public readonly pageStructureDisplayService: PageStructureDisplayService,
        private readonly themesService: ThemesService,
        private readonly brandsService: BrandsService,
        public readonly checkoutService: CheckoutService,
        private translateRouterService: TranslateRouterService,
        public wishlistService: WishlistService,
        private scrollBlocker: ScrollBlockerService,
        private breakpointsService: BreakpointsService,
        private impersonationService: ImpersonationService,
        private debtorService: DebtorService,
    ) {
    }

    public toggleMobileMenu(): void {
        this.mobileMenuIsOpen = not(this.mobileMenuIsOpen)

        if (! this.mobileMenuIsOpen && this.originalNavigationIsCollapsed) {
            this.navigationIsCollapsed = this.originalNavigationIsCollapsed
            this.originalNavigationIsCollapsed = undefined
        }

        if (this.mobileMenuIsOpen && this.navigationIsCollapsed) {
            this.originalNavigationIsCollapsed = true
            this.navigationIsCollapsed = false
        }

        if (this.mobileMenuIsOpen) {
            this.scrollBlocker.add('mobile-menu')
        } else {
            this.scrollBlocker.remove('mobile-menu')
        }
    }

    public toggleSearch(): void {
        if (this.mobileMenuIsOpen) {
            this.toggleMobileMenu()
        }

        if (this.navigationIsCollapsed || this.searchIsOpen) {
            this.searchIsOpen = not(this.searchIsOpen)
        }
    }

    public goToLoginPage(): void {
        this.authService.originUrl = this.translateRouterService.translateRouteLink('/dashboard') as string
        window.location.href = this.authService.getLoginUrl()
    }

    public isIntersecting(status: boolean, name: string): void {
        this.navigationIsCollapsed = ! status && name === 'header' && ! this.mobileMenuIsOpen

        this.changeCssNavigationHeightVariable()

        if (! this.navigationIsCollapsed && this.searchIsOpen) {
            this.toggleSearch()
        }
    }

    public changeCssNavigationHeightVariable(): void {
        const getValue = getComputedStyle(document.documentElement)
        const setValue = document.documentElement.style
        const navigationHeight = getValue.getPropertyValue('--mobile-navigation-normal')
        const navigationHeightCollapsed = getValue.getPropertyValue('--mobile-navigation-collapsed')

        if (this.navigationIsCollapsed) {
            setValue.setProperty('--current-navigation-height', navigationHeightCollapsed)
        } else {
            setValue.setProperty('--current-navigation-height', navigationHeight)
        }
    }

    public ngOnInit(): void {
        this.subscriptions.push(this.router.events.pipe(
            filter((event) => event instanceof ActivationStart),
        ).subscribe(() => [
            this.mobileMenuIsOpen = false,
            this.scrollBlocker.remove('mobile-menu'),
        ]))

        void this.checkoutService.getShoppingCartTotalItems()

        const categories = this.categoriesService.getTranslatedCategories()

        this.categories$ = categories.pipe(
            map((translatedCategories) => translatedCategories.map((cat) => ({
                label: cat.name,
                link: [
                    '/category',
                    cat.slug,
                ],
            }))),
        )

        this.brandsDropdown$ = this.brandsService.getTranslatedBrands().pipe(
            map((brands) => {
                if (! brands.length) {
                    return undefined
                }

                return ({
                    mainCategoryLabel: 'navigation.brands',
                    mainPageUrl: undefined,
                    categories: brands.map((brand) => ({
                        name: brand.name,
                        link: `/brand/${brand.slug}`,
                    })),
                    highlights: randomizeHighlightedProducts(brands),
                })
            }),
        )

        this.themesDropdown$ = this.themesService.getTranslatedThemes().pipe(
            map((themes) => ({
                mainCategoryLabel: 'navigation.themes',
                mainPageUrl: undefined,
                categories: themes.map((theme) => ({
                    name: theme.name,
                    link: '/theme/' + theme.slug,
                })),
                highlights: randomizeHighlightedProducts(themes),
            })),
        )

        this.dropdownCategories$ = categories.pipe(
            map((translatedCategories) => translatedCategories.map((cat) =>
                <CategoryDropdown>({
                    mainCategory: cat.name,
                    mainPageUrl: '/category/' + cat.slug,
                    categories: cat.children?.map((child: { name: string; slug: string }) => ({
                        name: child.name,
                        link: '/category/' + child.slug,
                    })).sort((a, b) => a.name.localeCompare(b.name)),
                    highlights: (cat.highlightedProducts ?? []).map((p) => ({
                        highlight: true,
                        image: p.images.length
                            ? (p.images.find((i) => i.type === ProductImageTypeEnum.Group)?.url ??
                                p.images[0].url)
                            : '',
                        title: p.name,
                        link: '/products/' + p.slug,
                    })),
                }),
            )),
        )

        this.subscriptions.push(this.breakpointsService.currentBreakpoint$.subscribe(() => {
            if (this.mobileMenuIsOpen) {
                this.toggleMobileMenu()
            }
        }))

        void this.setUserData()
    }

    public setUserData(): void {
        this.userSub = this.authService.user$.subscribe(async (data) => {
            if (! data) {
                return
            }

            if (data?.name) {
                this.userDropdown.dropdownButtonText = data.name
            }

            if (! data.isEnhanced && ! data?.debtorCode) {
                const containsEnhanceDebtorLink = this.userDropdown.dropdownItems
                    .some(item => item.routerLink === '/enhance-debtor')

                if (! containsEnhanceDebtorLink) {
                    this.userDropdown.dropdownItems.unshift(
                        {
                            itemLabel: 'auth.finish-profile.complete-profile',
                            routerLink: '/enhance-debtor',
                            underLine: true,
                        },
                    )
                }
            }

            const debtor = await firstValueFrom(this.debtorService.debtor$)

            if (! debtor?.debtorRights.allowedModules.datafeed) {
                const containsDataFeedLink = this.userDropdown.dropdownItems
                    .some(item => item.routerLink === '/data-feeds')

                if (containsDataFeedLink) {
                    const index = this.userDropdown.dropdownItems.findIndex(
                        (x) => x.itemLabel === 'navigation.productdata')
                    this.userDropdown.dropdownItems.splice(index, 1)

                    this.userDropdown.dropdownItems[index - 1].underLine = true
                }
            }
        })
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe())
        this.userSub.unsubscribe()
    }
}
