import { Observable, Subscription } from 'rxjs'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { LocalesEnum, PositionedPageEnum } from '@app-graphql/schema'
import {
    AuthService,
    BrandsService,
    CategoriesService,
    PageStructureDisplayService,
    ScrollService,
    ThemesService,
} from '@app-services'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { LocalizedRouting } from '@app-domains/navigation/types/navigation.types'

@Component({
    selector: 'app-site-footer',
    templateUrl: './site-footer.component.html',
    styleUrls: ['./site-footer.component.scss'],
})
export class SiteFooterComponent implements OnInit, OnDestroy {
    public categories$: Observable<LocalizedRouting[]>
    public themes$: Observable<LocalizedRouting[]>
    public brands$: Observable<LocalizedRouting[]>
    public readonly PositionedPageEnum = PositionedPageEnum
    public readonly LocalesEnum = LocalesEnum
    private localeSub: Subscription

    constructor(
        private readonly categoriesService: CategoriesService,
        public readonly localizationService: LocalizationService,
        public readonly pageStructureDisplayService: PageStructureDisplayService,
        public readonly authService: AuthService,
        private readonly brandsService: BrandsService,
        private readonly themeService: ThemesService,
        public readonly scrollService: ScrollService,
    ) {}

    public year: number = new Date().getFullYear()

    public ngOnInit(): void {
        this.localeSub = this.localizationService.currentLocale$.subscribe(() => {
            this.categories$ = this.categoriesService.getTranslatedCategories()
            this.themes$ = this.themeService.getTranslatedThemes()
            this.brands$ = this.brandsService.getTranslatedBrands()
        })
    }

    public ngOnDestroy(): void {
        if (this.localeSub) {
            this.localeSub.unsubscribe()
        }
    }
}
