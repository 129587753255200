import { locales, Localization } from '@aa-app-localization'
import { Injectable } from '@angular/core'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { LocalizedRouting } from '@app-domains/navigation/types/navigation.types'
import {
    CategoriesQueryService,
    CategoryFragment,
    CategoryQueryService,
    IndividualCategoryFragment,
    LocalesEnum,
} from '@app-graphql/schema'
import { translateCategory } from '@app-lib/category.lib'
import { combineLatestWith, firstValueFrom, Observable, of } from 'rxjs'
import { filter, map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class CategoriesService {
    categories: CategoryFragment[]

    constructor(
        public readonly localizationService: LocalizationService,
        public readonly categoriesQueryService: CategoriesQueryService,
        public readonly categoryQueryService: CategoryQueryService,
    ) {
    }

    public all(flattened: boolean = false) {
        return flattened ? this.flattenCategories(this.categories) : this.categories
    }

    public async initialize(): Promise<void> {
        if (this.categories) {
            return
        }

        this.categories = await this.fetchCategories()
        type T = Record<Localization.Locale, Record<string, string>>

        const xs = this.all(true).reduce<T>((acc, cat) => {
            for ( const locale of this.localizationService.langList ) {
                acc[locale][`category-${ cat.id }`] = cat.translations[locale].slug
            }
            return acc
        }, { en: {}, nl: {}, de: {}, fr: {} })

        for ( const locale of locales ) {
            this.localizationService.patchTranslations(locale, {
                ROUTES: xs[locale],
            })
        }
    }

    public flattenCategories(categories: CategoryFragment[]): CategoryFragment[] {
        return categories.reduce((flattened, category) => [
            ...flattened, category, ...(
                category.children
                    ? this.flattenCategories(category.children as CategoryFragment[])
                    : []
            ),
        ], [] as CategoryFragment[])
    }

    public findBySlug(slug: string, slugLocale?: Localization.Locale): CategoryFragment | null {
        const locale = slugLocale ?? this.localizationService.getCurrentLocale()
        const categories = this.all()
        for (const category of categories) {
            if (category.translations[locale].slug === slug) {
                return category
            }

            for (const child of category.children ?? []) {
                if (child?.translations[locale].slug === slug) {
                    return child as CategoryFragment
                }
            }
        }

        return null
    }

    public getTranslatedCategories(): Observable<LocalizedRouting[]> {
        return of(this.all()).pipe(
            combineLatestWith(this.localizationService.currentLocale$),
            map(([categories, lang]) => categories.map((c) => translateCategory(c, lang))),
        )
    }

    private async fetchCategories(): Promise<CategoryFragment[]> {
        const categories = await firstValueFrom(this.categoriesQueryService
            .fetch()
            .pipe(
                map(({ data }) => data?.categories ?? []),
                map((items) => items?.filter(
                    (item): item is CategoryFragment => !! item && item?.published),
                ),
            ),
        )

        return categories?.length ? categories : []
    }

    public fetchCategory(slug: string, locale: LocalesEnum): Observable<IndividualCategoryFragment> {
        return this.categoryQueryService.fetch({ slug, locale })
            .pipe(
                map(({ data }) => data.category),
                filter((category): category is IndividualCategoryFragment => !! category),
            )
    }

}
