<div class="card-container"
     [class.isSelected]="this.productSelectionService.EANCodes.has(product.ean)"
     (mouseenter)="hoverContainerSubject.next(true)"
     (mouseleave)="hoverContainerSubject.next(false)"
>
    <ng-template [ngTemplateOutlet]="cardContainer"
                 [ngTemplateOutletContext]="{
                    product: product,
                    animation: (animation$ | async)
                 }"
    ></ng-template>
</div>

<ng-template #cardContainer let-product="product" let-animation="animation" class="card">
    <ng-template [ngTemplateOutlet]="card"
                 [ngTemplateOutletContext]="{
                    product: product
                 }"
    ></ng-template>

    <div *ngIf="animation.value === 'expand' && (isInSelectMode$ | async) === false"
         class="hover"
         [@hoverState]="animation"
    >
        <ng-template [ngTemplateOutlet]="cardHoverState"
                     [ngTemplateOutletContext]="{
                        product: product
                     }"
        ></ng-template>
    </div>
</ng-template>

<ng-template #card let-product="product">
    <ng-container *ngIf="product | asProduct as product">
        <app-checkbox *ngIf="(isInSelectMode$ | async)"
                      [checked]="this.productSelectionService.EANCodes.has(product.ean)"
                      (changed)="changeSelectedState()"
                      [nativeMode]="true"
        ></app-checkbox>

        <a (click)="onCardClick()"
           [ssrHref]="'/products/' + product.slug | translateRoute"
           class="card-link"
        >
            <div *ngIf="product.newProduct"
                 class="new-label-container"
            >
                <div class="new-label">
                    <label class="white-bold">
                        {{ 'navigation.new' | translate }}
                    </label>
                </div>
            </div>

            <picture class="card-image">
                <source [srcset]="product.primaryImage ? (product.primaryImage | imageSize : 600) : 'assets/images/missing-image-icon.svg'"
                        type="image/webp"
                />

                <img [src]="
                        product.primaryImage ? (product.primaryImage | imageSize : 600)
                        : product.ambianceImage ? (product.ambianceImage | imageSize : 600)
                        : 'assets/images/missing-image-icon.svg'
                     "
                     [alt]="product.primaryImage ? ('common.image-of' | translate: { name: product.name }) : 'Missing image of ' + product.name"
                     loading="lazy"
                     fetchpriority="auto"
                     [class.x-large]="XLarge"
                >
            </picture>

            <div class="card-info">
                <h4>
                    <span class="card-title">
                        {{ product.brand }}
                    </span>

                    <span class="card-subtitle first-letter-uppercase">
                        {{ product.name }}
                    </span>
                </h4>

                <app-product-availability [status]="product.statusEnum"
                                          [statusTranslation]="product.status"
                                          [deliveryTimeInWeeks]="product.deliveryTimeInWeeks"
                ></app-product-availability>
            </div>
        </a>
    </ng-container>
</ng-template>

<ng-template #cardHoverState let-product="product">
    <ng-container *ngIf="product | asProduct as product">
        <div class="image-container">
            <a (click)="onCardClick()">
                <div *ngIf="product.newProduct"
                     class="new-label-container"
                >
                    <div class="new-label">
                        <label class="white-bold">
                            {{ 'navigation.new' | translate }}
                        </label>
                    </div>
                </div>

                <picture class="card-image">
                    <source type="image/webp"
                            [srcset]="product.ambianceImage ? (product.ambianceImage | imageSize : 600) : (product.primaryImage | imageSize : 600) ?? 'assets/images/missing-image-icon.svg' "
                    />
                    <img [src]="
                            product.ambianceImage ? (product.ambianceImage | imageSize : 600)
                            : product.primaryImage ? (product.primaryImage | imageSize : 600)
                            : 'assets/images/missing-image-icon.svg'
                         "
                         [alt]="product.ambianceImage ? ('common.image-of' | translate: { name: product.name }) : 'Missing image of ' + product.name"
                         loading="lazy"
                         fetchpriority="auto"
                    >
                </picture>
            </a>

            <ng-container *ngIf="authService.user$ | async">
                <div class="wishlist-container">
                    <app-button *ngIf="theme === 'product'"
                                (click)="addProductToWishlist($event)"
                                [icon]="product.inFavourites ? 'heart-filled' : 'heart'"
                                layout="icon-only"
                                theme="line"
                                size="smaller"
                    ></app-button>

                    <app-button *ngIf="theme === 'wishlist'"
                                (click)="removeFromWishlist($event)"
                                [state]="removingFromWishlist ? 'loading' : 'idle'"
                                icon="delete"
                                layout="icon-only"
                                theme="line"
                    ></app-button>
                </div>
            </ng-container>
        </div>

        <div class="card-info">
            <div (click)="onCardClick()">
                <h4>
                    <span class="card-title">
                        {{ product.brand }}
                    </span>

                    <span class="card-subtitle first-letter-uppercase">
                        {{ product.name }}
                    </span>
                </h4>

                <app-product-availability [status]="product.statusEnum"
                                          [statusTranslation]="product.status"
                                          [deliveryTimeInWeeks]="product.deliveryTimeInWeeks"
                ></app-product-availability>
            </div>

            <div class="line"></div>

            <div class="extra-info">
                <div class="info-grid"
                     [class.info-grid-large]="highlightItem"
                >
                    <div class="info">
                        <label class="small">
                            {{ 'products.article-code' | translate }}
                        </label>
                        <label class="bold small">
                            {{ product.codeLocal }}
                        </label>
                    </div>
                    <div class="info">
                        <label class="small">
                            {{ 'products.ean' | translate }}
                        </label>
                        <label class="bold small">
                            {{ product.ean }}
                        </label>
                    </div>
                    <div class="info">
                        <label class="small">
                            {{ 'catalog-index.refinement-lists.brand' | translate }}
                        </label>

                        <label class="bold small">
                            {{ product.brand }}
                        </label>
                    </div>
                    <div class="info">
                        <label class="small">
                            {{ 'catalog-index.refinement-lists.material' | translate }}
                        </label>

                        <label class="bold small">
                            <ng-container *ngIf="product.subMaterials.length else noMaterials">
                                <ng-container *ngIf="product.subMaterials[0] !== 'Unknown' else noMaterials">
                                    {{ product.subMaterials }}
                                </ng-container>
                            </ng-container>

                            <ng-template #noMaterials>
                                {{ 'common.not-available' | translate }}
                            </ng-template>
                        </label>
                    </div>

                    <div class="info">
                        <label class="small">
                            {{ 'catalog-index.refinement-lists.color' | translate }}
                        </label>

                        <label class="bold small color">
                            <ng-container *ngIf="product.subColors.length else noColors">
                                <ng-container *ngFor="let color of product.subColors">
                                    <span>
                                        {{ color?.name || color }}
                                    </span>
                                </ng-container>
                            </ng-container>

                            <ng-template #noColors>
                                {{ 'common.not-available' | translate }}
                            </ng-template>
                        </label>
                    </div>

                    <div class="info">
                        <label class="small">
                            {{ 'products.dimensions-h-b-d' | translate }}
                        </label>
                        <label class="bold small lowercase">
                            {{ product.dimensions?.height }} x {{ product.dimensions?.width }}
                            x {{ product.dimensions?.length }} {{ product.dimensions?.units }}
                        </label>
                    </div>

                    <div *ngIf="product.productOrderSteps"
                         class="info"
                    >
                        <label class="small">
                            {{ 'products.purchasing-unit' | translate }}
                        </label>
                        <label class="bold small">
                            {{ product.productOrderSteps }}
                        </label>
                    </div>
                </div>

                <div class="line"></div>

                <ng-container *ngIf="authService.user$ | async as user; else unauthorisedTemplate">
                    <ng-container *ngIf="user.debtorCode else unauthorisedTemplate">
                        <ng-container [ngTemplateOutlet]="authorisedTemplate"
                        ></ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #unauthorisedTemplate>
    <div class="footer">
        <div class="cart-controls" [ngClass]="highlightItem ? 'highlighted' : ''">
            <div class="price-container">
                <div class="item">
                    <div class="prices-container">
                        <ng-container *ngIf="! (product.suggestedStandardRetailPrice | isOnSale:product.suggestedRetailPrice) else sale">
                            <div *ngIf="((debtorService.isNL | async) ? product.suggestedStandardRetailPrice : product.suggestedStandardRetailPriceISE) | priceSplit : true as splitPrice"
                                 class="split-price"
                            >
                                <label class="bold">
                                    €
                                </label>

                                <label class="bold stretch">
                                    {{ splitPrice[0] }}.
                                </label>

                                <sup class="small stretch">
                                    {{ splitPrice[1] }}
                                </sup>
                            </div>
                        </ng-container>

                        <ng-template #sale>
                            <div *ngIf="((debtorService.isNL | async) ? product.suggestedStandardRetailPrice : product.suggestedStandardRetailPriceISE) | priceSplit : true as splitPrice"
                                 class="split-price old-price"
                            >
                                <label class="bold stretch small-size">
                                    {{ splitPrice[0] }}.{{ splitPrice[1] }}
                                </label>
                            </div>

                            <div *ngIf="((debtorService.isNL | async) ? product.suggestedRetailPrice : product.suggestedRetailPriceISE) | priceSplit : true as splitPrice"
                                 class="split-price new-price"
                            >
                                <label class="bold">
                                    €
                                </label>
                                <label class="bold stretch">
                                    {{ splitPrice[0] }}.
                                </label>
                                <sup class="small stretch">
                                    {{ splitPrice[1] }}
                                </sup>
                            </div>
                        </ng-template>
                    </div>

                    <label class="small">
                        {{ 'products.suggested-retail-price-vat' | translate }}
                    </label>
                </div>
            </div>

            <div class="actions">
                <div class="normal-actions">
                    <app-button (click)="debtorProfileIsIncomplete ? authService.openNoDebtorDialog() : authService.openLoginDialog()"
                                layout="icon-label"
                                icon="add-to-cart"
                    >
                        {{ 'products.add-to-cart' | translate }}
                    </app-button>

                    <app-button [link]="'/dealers' | translateRoute"
                                theme="line"
                                layout="label-only"
                                type="link"
                    >
                        {{ 'products.find-dealer' | translate }}
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #authorisedTemplate>
    <div class="footer">
        <div class="cart-controls"
             [ngClass]="highlightItem ? 'highlighted' : ''"
        >
            <div class="price-container"
                 [class.less-spacing]="product.statusEnum === statusEnum.Sale"
            >
                <ng-container *ngIf="authService.user$ | async as user">
                    <ng-container *ngIf="! user.hidePurchasePrice">
                        <ng-container *ngIf="productEnhanceService.enhancedProducts$ | async; else loadingEnhancedProducts">
                            <ng-container *ngIf="productEnhanceService.getEnhancedProduct(product.ean) as enhancedProduct">
                                <div *ngIf="enhancedProduct.price"
                                     class="item"
                                >
                                    <div class="prices-container">
                                        <ng-container *ngIf="! (enhancedProduct.price.standardPrice | isOnSale : enhancedProduct.price.price) else sale">
                                            <div *ngIf="enhancedProduct.price.standardPrice | priceSplit : true as splitPrice"
                                                 class="split-price"
                                            >
                                                <ng-container *ngIf="splitPrice[0] === 'NaN'">
                                                    <label class="bold small-size">
                                                        {{ 'common.not-available' | translate }}
                                                    </label>
                                                </ng-container>

                                                <ng-container *ngIf="splitPrice[0] !== 'NaN'">
                                                    <label class="bold">
                                                        €
                                                    </label>

                                                    <label class="bold stretch">
                                                        {{ splitPrice[0] }}.
                                                    </label>

                                                    <sup class="small stretch">
                                                        {{ splitPrice[1] }}
                                                    </sup>
                                                </ng-container>
                                            </div>
                                        </ng-container>

                                        <ng-template #sale>
                                            <div *ngIf="enhancedProduct.price.standardPrice | priceSplit : true as splitPrice"
                                                 class="split-price old-price"
                                            >
                                                <label class="bold stretch small-size">
                                                    {{ splitPrice[0] }}.{{ splitPrice[1] }}
                                                </label>
                                            </div>

                                            <div *ngIf="enhancedProduct.price.price | priceSplit : true as splitPrice"
                                                 class="split-price new-price"
                                            >
                                                <label class="bold">
                                                    €
                                                </label>
                                                <label class="bold stretch">
                                                    {{ splitPrice[0] }}.
                                                </label>
                                                <sup class="small stretch">
                                                    {{ splitPrice[1] }}
                                                </sup>
                                            </div>
                                        </ng-template>
                                    </div>

                                    <label class="small">
                                        {{ 'products.purchase-price-vat' | translate }}
                                    </label>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-template #loadingEnhancedProducts>
                    <app-spinner [size]="32" class=""></app-spinner>
                </ng-template>

                <div class="item">
                    <div class="prices-container">
                        <ng-container *ngIf="!(product.suggestedStandardRetailPrice | isOnSale : product.suggestedRetailPrice) else sale">
                            <div *ngIf="((debtorService.isNL | async) ? product.suggestedStandardRetailPrice : product.suggestedStandardRetailPriceISE) | priceSplit : true as splitPrice"
                                 class="split-price"
                            >
                                <label class="bold">
                                    €
                                </label>

                                <label class="bold stretch">
                                    {{ splitPrice[0] }}.
                                </label>

                                <sup class="small stretch">
                                    {{ splitPrice[1] }}
                                </sup>
                            </div>
                        </ng-container>

                        <ng-template #sale>
                            <div *ngIf="((debtorService.isNL | async) ? product.suggestedStandardRetailPrice : product.suggestedStandardRetailPriceISE) | priceSplit : true as splitPrice"
                                 class="split-price old-price"
                            >
                                <label class="bold stretch small-size">
                                    {{ splitPrice[0] }}.{{ splitPrice[1] }}
                                </label>
                            </div>

                            <div *ngIf="((debtorService.isNL | async) ? product.suggestedRetailPrice : product.suggestedRetailPriceISE) | priceSplit : true as splitPrice"
                                 class="split-price new-price"
                            >
                                <label class="bold">
                                    €
                                </label>
                                <label class="bold stretch">
                                    {{ splitPrice[0] }}.
                                </label>
                                <sup class="small stretch">
                                    {{ splitPrice[1] }}
                                </sup>
                            </div>
                        </ng-template>
                    </div>

                    <label class="small">
                        {{ 'products.suggested-retail-price-vat' | translate }}
                    </label>
                </div>
            </div>

            <div class="purchase-options">
                <div class="quantity">
                    <app-input [control]="quantityControl"
                               step="1"
                               min="0"
                               type="number"
                               size="smaller"
                               [disabled]="product.debtorPrice | cannotBeAddedToCart: product.statusEnum"
                               [noValidate]="true"
                    ></app-input>
                </div>

                <div class="add-to-cart">
                    <app-button [disabled]="product.debtorPrice | cannotBeAddedToCart: product.statusEnum"
                                [state]="(isLoading$ | async) ? 'loading' : 'idle'"
                                (clicked)="addToCart()"
                                layout="icon-label"
                                icon="add-to-cart"
                                size="smaller"
                    >
                        {{ (product.debtorPrice | cannotBeAddedToCart: product.statusEnum) ? ('common.not-available' | translate) : ('products.add-to-cart' | translate) }}
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
