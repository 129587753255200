<div *ngIf="localizationService.currentLocale$ | async as locale"
     [ngClass]="pageStructureDisplayService.showFooter ? 'footer-bar' : 'hidden'"
>
    <app-grid [colGap]="true">
        <app-cell mobile="0:12" laptop="0:2" [responsiveStyle$]="{
            mobile: {
                gridRow: '5'
            },
            tablet: {
                gridRow: '5'
            }
        } | responsiveStyle$">
            <div class="footer__logo">
                <a [routerLink]="'/' | translateRoute"
                   (click)="scrollService.scrollToTop()"
                   appIcon="logo"></a>
            </div>
        </app-cell>

        <app-cell mobile="0:12" tablet="0:6" laptop="2:6">
            <div class="footer__products footer__list--separate">
                <h2 class="footer__title subheading">{{ 'common.products' | translate }}</h2>
                <h2 class="footer__title subheading">{{ 'navigation.brands' | translate }}</h2>

                <ul *ngIf="categories$ | async as categories" class="footer__list">
                    <li>
                        <a [routerLink]="'/products/new' | translateRoute"
                           (click)="scrollService.scrollToTop()"
                           class="footer__link footer__link--separate-after"
                        >
                            {{ 'navigation.new' | translate }}
                        </a>
                    </li>

                    <li *ngFor="let category of categories; let last = last">
                        <a [routerLink]="'/category/' + category.slug | translateRoute"
                           (click)="scrollService.scrollToTop()"
                           class="footer__link"
                           [class.footer__link--separate-after]="last"
                        >
                            {{ category.name }}
                        </a>
                    </li>

                    <li>
                        <a [routerLink]="'/products/sale' | translateRoute"
                           (click)="scrollService.scrollToTop()"
                           class="footer__link"
                        >
                            {{ 'navigation.sale' | translate }}
                        </a>
                    </li>
                </ul>

                <ul class="footer__list">
                    <ng-container *ngIf="brands$ | async as brands">
                        <li *ngFor="let brand of brands">
                            <a [routerLink]="'/brand/' + brand.slug | translateRoute"
                               (click)="scrollService.scrollToTop()"
                               class="footer__link"
                            >
                                {{ brand.name }}
                            </a>
                        </li>
                    </ng-container>

                    <h2 class="footer__title subheading margin-top">{{ 'navigation.themes' | translate }}</h2>

                    <ng-container *ngIf="themes$ | async as themes">
                        <li *ngFor="let theme of themes">
                            <a [routerLink]="'/theme/' + theme.slug | translateRoute"
                               (click)="scrollService.scrollToTop()"
                               class="footer__link"
                            >
                                {{ theme.name }}
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </app-cell>

        <app-cell mobile="0:12" tablet="6:12" laptop="6:8">
            <div class="footer__de-eekhoorn footer__list--separate">
                <h2 class="footer__title subheading">{{ 'global.name' | translate }}</h2>
                <ul class="footer__list">
                    <li>
                        <a [routerLink]="PositionedPageEnum.Projects | positionedPageLink | async"
                           class="footer__link"
                        >
                            {{ 'navigation.projects' | translate }}
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="((authService.user$ | async) ? '/p/experience-center-business': '/experience-center') | translateRoute"
                           (click)="scrollService.scrollToTop()"
                           class="footer__link"
                        >
                            {{ 'navigation.experience-center' | translate }}
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="'/dealers' | translateRoute"
                           (click)="scrollService.scrollToTop()"
                           class="footer__link"
                        >
                            {{ 'navigation.dealers' | translate }}
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="PositionedPageEnum.Brochures | positionedPageLink | async"
                           (click)="scrollService.scrollToTop()"
                           class="footer__link footer__link--separate-after"
                        >
                            {{ 'navigation.brochures' | translate }}
                        </a>
                    </li>
                </ul>
                <ul class="footer__list">
                    <li>
                        <a [routerLink]="PositionedPageEnum.AboutUs | positionedPageLink | async"
                           (click)="scrollService.scrollToTop()"
                           class="footer__link"
                        >
                            {{ 'navigation.about-us' | translate }}
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="'/news' | translateRoute"
                           (click)="scrollService.scrollToTop()"
                           class="footer__link"
                        >
                            {{ 'navigation.news' | translate }}
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="'/events' | translateRoute"
                           (click)="scrollService.scrollToTop()"
                           class="footer__link"
                        >
                            {{ 'navigation.events' | translate }}
                        </a>
                    </li>
                    <li>
                        <a [href]="'https://werkenbij.deeekhoorn.com/' + (locale !== LocalesEnum.Nl ? LocalesEnum.En : LocalesEnum.Nl)"
                           target="_blank"
                           class="footer__link"
                        >
                            {{ 'navigation.jobs' | translate }}
                        </a>
                    </li>
                </ul>
            </div>
        </app-cell>

        <app-cell mobile="0:12" laptop="9:12" [responsiveStyle$]="{
            mobile: {
                gridRow: '3'
                },
            tablet: {
                gridRow: '1'
            }
        } | responsiveStyle$">
            <div class="footer__connect">
                <h2 class="footer__title subheading">{{ 'navigation.contact' | translate }}</h2>
                <ul class="footer__list">
                    <li>
                        <a [routerLink]="PositionedPageEnum.Service | positionedPageLink | async"
                           (click)="scrollService.scrollToTop()"
                           class="footer__link"
                        >
                            {{ 'navigation.service' | translate }}
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="PositionedPageEnum.Contact | positionedPageLink | async"
                           (click)="scrollService.scrollToTop()"
                           class="footer__link"
                        >
                            {{ 'navigation.contact' | translate }}
                        </a>
                    </li>
                </ul>

                <h2 class="footer__title subheading margin-top">{{ 'template.site-footer.follow-us-on' | translate }}</h2>
                <div class="footer__social-container">
                    <a href="https://www.facebook.com/deeekhoorndutchfurniture"
                       target="_blank"
                       class="footer__social-link"
                       appIcon="facebook"
                    ></a>
                    <a href="https://nl.linkedin.com/company/de-eekhoorn-dutch-furniture"
                       target="_blank"
                       class="footer__social-link"
                       appIcon="linkedin"
                    ></a>
                    <a href="http://www.instagram.com/deeekhoorn"
                       target="_blank"
                       class="footer__social-link"
                       appIcon="instagram"
                    ></a>
                </div>
            </div>
        </app-cell>

        <app-cell mobile="0:12" tablet="0:12" laptop="2:12">
            <div class="footer__bottom">
                <div class="footer__copyright">© {{ year }} {{ 'global.name' | translate }}</div>
                <div class="footer__notices">
                    <a [routerLink]="PositionedPageEnum.PrivacyPolicy | positionedPageLink | async">
                        {{ 'navigation.privacy-policy' | translate }}
                    </a>

                    <a [routerLink]="PositionedPageEnum.TermsAndConditions | positionedPageLink | async"
                       (click)="scrollService.scrollToTop()"
                    >
                        {{ PositionedPageEnum.TermsAndConditions | positionedPageTitle | async }}
                    </a>

                    <a [routerLink]="PositionedPageEnum.Disclaimer | positionedPageLink | async"
                       (click)="scrollService.scrollToTop()"
                    >
                        {{ PositionedPageEnum.Disclaimer | positionedPageTitle | async }}
                    </a>
                </div>
            </div>
        </app-cell>
    </app-grid>
</div>
