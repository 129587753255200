import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client'

import { FormControl } from '@angular/forms'
import { Injectable } from '@angular/core'

import {
    AddToAssortmentMutation,
    AddToAssortmentMutationService, AssortmentEansQuery,
    AssortmentEansQueryService,
    AssortmentEansQueryVariables,
    AssortmentsQuery,
    AssortmentsQueryService,
    AssortmentsQueryVariables,
    MinimalAssortmentsQuery,
    MinimalAssortmentsQueryService,
    MinimalAssortmentsQueryVariables, RemoveFromAssortmentMutation,
    RemoveFromAssortmentMutationService,
    RemoveFromAssortmentMutationVariables,
    Sort,
} from '@app-graphql/schema'
import { ApolloDatasource } from '@app-lib/apollo-datasource.lib'
import { DownloadService, WishlistService } from '@app-services'
import { SnackbarService } from '@app-domains/ui/services/snackbar/snackbar.service'
import { MutationResult } from 'apollo-angular'

@Injectable({
    providedIn: 'root',
})
export class AssortmentService {

    public selectedAssortments = new FormControl<string[]>([])
    public selectAllControl = new FormControl<boolean>(false)
    public onlyShowStock = new FormControl<boolean>(false, { nonNullable: true })
    public sortAsc = new FormControl<boolean | undefined>(undefined)

    public selectedAction = new FormControl('')
    public selectionBarChangedData$ = new BehaviorSubject(false)

    constructor(
        private readonly addToAssortmentMutationService: AddToAssortmentMutationService,
        private readonly assortmentsQueryService: AssortmentsQueryService,
        private readonly minimalAssortmentsQueryService: MinimalAssortmentsQueryService,
        private readonly assortmentEansQueryService: AssortmentEansQueryService,
        private readonly removeFromAssortmentMutationService: RemoveFromAssortmentMutationService,
        private readonly downloadService: DownloadService,
        private readonly snackbarService: SnackbarService,
        private readonly wishListService: WishlistService,
    ) {
    }

    public addToAssortment(eanCodes: string[]): Observable<MutationResult<AddToAssortmentMutation>> {
        return this.addToAssortmentMutationService.mutate({ eanCodes: eanCodes })
    }

    public getAssortments(input: AssortmentsQueryVariables): Observable<ApolloQueryResult<AssortmentsQuery>> {
        return this.assortmentsQueryService.fetch(input)
    }

    public removeFromAssortment(input: RemoveFromAssortmentMutationVariables)
        : Observable<MutationResult<RemoveFromAssortmentMutation>> {
        return this.removeFromAssortmentMutationService.mutate(input)
    }

    public getMinimalAssortments(
        input: MinimalAssortmentsQueryVariables,
    ): Observable<ApolloQueryResult<MinimalAssortmentsQuery>> {
        return this.minimalAssortmentsQueryService.fetch(input)
    }

    public getAssortmentEans(input: AssortmentEansQueryVariables): Observable<ApolloQueryResult<AssortmentEansQuery>> {
        return this.assortmentEansQueryService.fetch(input)
    }

    public getDatasource(page: number = 1) {
        return new ApolloDatasource(
            this.assortmentsQueryService,
            'assortments',
            {
                orderBy: this.sortAsc !== undefined ? {
                    productName: this.sortAsc.value ? Sort.Asc : Sort.Desc,
                } : {},
                filterBy: {
                    outOfStock: this.onlyShowStock.value,
                },
            },
            {
                page,
                first: 8,
            },
        )
    }

    public async addSelectionToWishlist(): Promise<void> {
        if (! this.selectedAssortments.value?.length) {
            return
        }

        const result = await firstValueFrom(this.wishListService.addToWishlist(this.selectedAssortments.value))

        if (result.errors) {
            this.snackbarService.create({
                content: 'common.error',
                dismissible: true,
            }, 'bottom')
            return
        }

        this.snackbarService.create({
            content: 'products.added-to-wishlist',
            dismissible: true,
        }, 'bottom')

        this.closeFooter()
        await this.wishListService.refreshWishlist()
    }

    public closeFooter(): void {
        this.deselectAll()
    }

    public deselectAll(): void {
        this.selectedAssortments.patchValue([])
    }

    public async openConfigDialog(): Promise<void> {
        await this.downloadService.openProductDownloadConfigModal(
            (this.selectedAssortments.value ?? []).map((v) => v.toString()),
        )
    }
}
