import { Injectable } from '@angular/core'
import { devConsole } from '@app-lib/common.lib'

import { AnalyticsService } from '@app-domains/analytics/analytics.service'
import {
    AuthService,
    BrandsService,
    CategoriesService,
    DownloadService,
    ThemesService,
} from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class BootService {
    public bootLoading = false

    constructor(
        private readonly categoriesService: CategoriesService,
        private readonly authService: AuthService,
        private readonly brandsService: BrandsService,
        private readonly themesService: ThemesService,
        private readonly analyticsService: AnalyticsService,
        private readonly downloadService: DownloadService,
    ) {
    }

    public async bootApplication(): Promise<void> {
        this.bootLoading = true

        devConsole.time('boot')

        await Promise.all([
            this.initializeAuthService(),
            this.initializeCategories(),
            this.initializeBrandsService(),
            this.initializeThemesService(),
            this.initializeAnalyticsService(),
            this.initializeDownloadService(),
        ])

        devConsole.timeEnd('boot')

        this.bootLoading = false
    }

    private async initializeAnalyticsService(): Promise<void> {
        this.analyticsService.setGoogleAnalyticsScript()
        this.analyticsService.startRouteLogging()
    }

    private async initializeDownloadService(): Promise<void> {
        await this.downloadService.initialize()
    }

    private async initializeCategories(): Promise<void> {
        await this.categoriesService.initialize()
    }

    private async initializeAuthService(): Promise<void> {
        await this.authService.initialize()
    }

    private async initializeBrandsService(): Promise<void> {
        await this.brandsService.initialize()
    }

    private async initializeThemesService(): Promise<void> {
        await this.themesService.initialize()
    }
}
